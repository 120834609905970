<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="pb-2">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <accounts-payable-filter-form
        :filters="filters"
        :period-is-required="true"
      />
    </e-filters>

    <b-row
      v-show="false"
      class="mb-2"
    >
      <b-col md="3">
        <e-total-summary-card
          :title="$t('Pendente')"
          title-class="text-warning"
          :value="summaryData.pending.value"
          has-predicted-value
          :predicted-value="summaryData.pending.predicted"
        >
          <p class="mb-0">
            Contas abertas: <br>
            {{ summaryData.pending.secondValue }}
          </p>
        </e-total-summary-card>
      </b-col>
      <b-col md="3">
        <e-total-summary-card
          :title="$t('Em atraso')"
          title-class="text-danger"
          :value="summaryData.overdue.value"
          has-predicted-value
          :predicted-value="summaryData.overdue.predicted"
        >
          <p class="mb-0">
            Contas em atraso: <br>
            {{ summaryData.overdue.secondValue }}
          </p>
        </e-total-summary-card>
      </b-col>
      <b-col md="3">
        <e-total-summary-card
          :title="$t('Quitado')"
          title-class="text-primary"
          :value="summaryData.paidOff.value"
        >
          <p class="mb-0">
            Total pago: <br>
            {{ summaryData.paidOff.secondValue | currency }}
          </p>
        </e-total-summary-card>
      </b-col>
      <b-col md="3">
        <e-total-summary-card
          :title="$t('Total')"
          title-class="text-info"
          :value="summaryData.total.value"
          has-predicted-value
          :predicted-value="summaryData.total.predicted"
        >
          <p class="mb-0">
            Total pago: <br>
            {{ summaryData.total.secondValue | currency }}
          </p>
        </e-total-summary-card>
      </b-col>
    </b-row>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <accounts-payable-table
        :items="accountsPayable"
        :sorting="sorting"
        :summary-fields="summaryFields"
        :show-read-only="true"
        @show-detail="onShowDetail"
        @fetch-data="getData"
        @show-pay-modal="showPayModal"
        @reverse-payment="localReversePayment"
        @update-account-payable="updateBill"
        @delete-installment="onDeleteInstallment"
        @clone-accounts-payable="cloneAccountsPayable"
      />

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="accounts-payable-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <pay-installment-modal
      ref="payInstallmentModal"
      @after-confirm="({ itemList }) => onAfterPay(itemList)"
      @remove-installment="
        (item) => {
          removeInstallmentsToPay(item)
          getData()
        }
      "
    />

    <accounts-payable-installment-info-sidebar ref="accountsPayableInstallmentInfoSidebar" />

    <fab
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :fixed-tooltip="true"
      :actions="fabActions"
      @clean-bulk-payment="cleanBulkPayemnt"
      @confirm-bulk-payment="showBulkPayModal"
      @add-new="goToCreateBill"
      @add-import="createBulkBill"
      @click="() => {}"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import Fab from '@/views/components/FAB.vue'
import { mapActions, mapState } from 'vuex'
import { formulateValidation, translate } from '@/mixins'
import EFilters from '@/views/components/EFilters.vue'
import ETotalSummaryCard from '../../../components/ETotalSummaryCard.vue'
import PayInstallmentModal from './components/PayInstallmentModal.vue'
import AccountsPayableFilterForm from './components/form/AccountsPayableFilterForm.vue'
import AccountsPayableTable from './components/AccountsPayableTable.vue'
import AccountsPayableInstallmentInfoSidebar from './components/AccountsPayableInstallmentInfoSidebar.vue'

export default {
  components: {
    BCardActions,
    EPageSizeSelector,
    EPaginationSummary,
    Fab,
    BRow,
    BCol,
    BPagination,
    ETotalSummaryCard,
    EFilters,
    PayInstallmentModal,
    AccountsPayableFilterForm,
    AccountsPayableTable,
    AccountsPayableInstallmentInfoSidebar,
  },

  mixins: [formulateValidation, translate],

  data() {
    return {
      fetching: false,
      summaryData: {
        pending: {
          value: 0,
          predicted: 0,
          secondValue: 0,
        },
        overdue: {
          value: 0,
          predicted: 0,
          secondValue: 0,
        },
        paidOff: {
          value: 0,
          secondValue: 0,
        },
        total: {
          value: 0,
          predicted: 0,
          secondValue: 0,
        },
      },
    }
  },

  computed: {
    ...mapState('pages/financial/accountsPayable', [
      'filters',
      'paging',
      'sorting',
      'accountsPayable',
      'selectedInstallments',
      'summaryFields',
    ]),

    hasBulkPayment() {
      return this.selectedInstallments.length > 0
    },

    countBulkPayment() {
      return this.selectedInstallments.length
    },

    fabActions() {
      return [
        {
          name: 'clean-bulk-payment',
          icon: 'backspace',
          color: 'red',
          tooltip: `${this.$t('Limpar pagamento em massa')} (${this.countBulkPayment})`,
          hide: !this.hasBulkPayment,
        },
        {
          name: 'confirm-bulk-payment',
          icon: 'payments',
          color: 'green',
          tooltip: `${this.$t('Realizar pagamento em massa')} (${this.countBulkPayment})`,
          hide: !this.hasBulkPayment,
        },
        {
          name: 'add-new',
          icon: 'add',
          color: 'green',
          tooltip: this.$t('Adicionar Despesa'),
          hide: !this.$can('Create', 'AccountsPayable'),
        },
        {
          name: 'add-import',
          icon: 'note_add',
          color: 'green',
          tooltip: this.$t('Importar Despesa'),
          hide: !this.$can('BulkCreate', 'AccountsPayable'),
        },
      ].filter(act => !act.hide)
    },
  },

  mounted() {
    this.getData()
    this.fetchManagementAccounts()
    this.fetchCheckingAccounts()
  },

  methods: {
    ...mapActions('app', ['fetchCheckingAccounts']),
    ...mapActions('common/managementAccounts', ['fetchManagementAccounts']),
    ...mapActions('pages/financial/accountsPayable', [
      'fetchAccountsPayable',
      'resetFilters',
      'setPageSize',
      'setCurrentPage',
      'addInstallmentsToPay',
      'reversePayment',
      'removeInstallmentsToPay',
      'cleanInstallmentsToPay',
      'deleteInstallment',
    ]),
    ...mapActions('pages/financial/accountsPayable/expenseMaintain', {
      cleanExpenseMaintain: 'cleanState',
    }),
    ...mapActions('pages/financial/accountsPayable/accountsPayableBulkMaintain', {
      cleanBulkMaintain: 'cleanState',
    }),
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchAccountsPayable()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onShowDetail(row) {
      this.$refs.accountsPayableInstallmentInfoSidebar.show(row.item)
    },
    showPayModal(row) {
      const { item } = row
      this.$refs.payInstallmentModal.showModal(false, [item.id])
    },
    async localReversePayment(installment) {
      const confirmed = await this.confirm({
        text: this.$t('O pagamento será cancelado e o lançamento na conta corrente será excluído.'),
      })
      if (!confirmed) return

      try {
        this.fetching = true
        await this.reversePayment(installment.financialWriteOffId)
        this.showSuccess({ message: this.$t('Baixa estornada com sucesso') })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
    showBulkPayModal() {
      const installmentIdList = this.selectedInstallments.map(i => i.id)
      this.$refs.payInstallmentModal.showModal(true, installmentIdList)
    },
    async onAfterPay(itemList) {
      await Promise.all(
        itemList.map(async item => {
          await this.removeInstallmentsToPay(item)
        })
      )
      await this.getData()
    },

    async cleanBulkPayemnt() {
      const confirmed = await this.confirm({
        text: this.$t('Os pagamentos selecionados serão desmarcados'),
      })
      if (!confirmed) return

      this.cleanInstallmentsToPay()
      this.getData()
    },

    async onRowClickToSelectPay(item) {
      if (item.paymentDate === null) {
        const checkValue = !item.checkedToPay
        // eslint-disable-next-line no-param-reassign
        item.checkedToPay = checkValue
        this.onSelectToPay(checkValue, item)
      }
    },

    async onSelectToPay(value, item) {
      try {
        if (value) {
          await this.addInstallmentsToPay(item)
        } else {
          await this.removeInstallmentsToPay(item)
        }
      } catch (error) {
        this.showWarning({ message: error.message })
        // eslint-disable-next-line no-param-reassign
        item.checkedToPay = !value
      }
    },

    goToCreateBill() {
      this.cleanExpenseMaintain()
      this.$router.push({ name: 'expense-add' })
    },
    updateBill(row) {
      this.cleanExpenseMaintain()
      this.$router.push({ name: 'expense-maintain', params: { id: row.item.accountsPayable.id } })
    },
    createBulkBill() {
      this.cleanBulkMaintain()
      this.$router.push({ name: 'expense-bulk-add' })
    },
    async onDeleteInstallment(installmentId) {
      try {
        this.fetching = true
        await this.deleteInstallment(installmentId)
        this.showSuccess({ message: this.$t('Parcela excluída com sucesso') })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
    cloneAccountsPayable(accountsPayableId) {
      this.cleanExpenseMaintain()
      this.$router.push({ name: 'expense-add', params: { cloneFromId: accountsPayableId } })
    },
  },
}
</script>

<style lang="scss"></style>
