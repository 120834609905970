var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "pb-2" },
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c("accounts-payable-filter-form", {
            attrs: { filters: _vm.filters, "period-is-required": true },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          staticClass: "mb-2",
        },
        [
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c(
                "e-total-summary-card",
                {
                  attrs: {
                    title: _vm.$t("Pendente"),
                    "title-class": "text-warning",
                    value: _vm.summaryData.pending.value,
                    "has-predicted-value": "",
                    "predicted-value": _vm.summaryData.pending.predicted,
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" Contas abertas: "),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.summaryData.pending.secondValue) + " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c(
                "e-total-summary-card",
                {
                  attrs: {
                    title: _vm.$t("Em atraso"),
                    "title-class": "text-danger",
                    value: _vm.summaryData.overdue.value,
                    "has-predicted-value": "",
                    "predicted-value": _vm.summaryData.overdue.predicted,
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" Contas em atraso: "),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.summaryData.overdue.secondValue) + " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c(
                "e-total-summary-card",
                {
                  attrs: {
                    title: _vm.$t("Quitado"),
                    "title-class": "text-primary",
                    value: _vm.summaryData.paidOff.value,
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" Total pago: "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currency")(
                            _vm.summaryData.paidOff.secondValue
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c(
                "e-total-summary-card",
                {
                  attrs: {
                    title: _vm.$t("Total"),
                    "title-class": "text-info",
                    value: _vm.summaryData.total.value,
                    "has-predicted-value": "",
                    "predicted-value": _vm.summaryData.total.predicted,
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" Total pago: "),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("currency")(_vm.summaryData.total.secondValue)
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("accounts-payable-table", {
            attrs: {
              items: _vm.accountsPayable,
              sorting: _vm.sorting,
              "summary-fields": _vm.summaryFields,
              "show-read-only": true,
            },
            on: {
              "show-detail": _vm.onShowDetail,
              "fetch-data": _vm.getData,
              "show-pay-modal": _vm.showPayModal,
              "reverse-payment": _vm.localReversePayment,
              "update-account-payable": _vm.updateBill,
              "delete-installment": _vm.onDeleteInstallment,
              "clone-accounts-payable": _vm.cloneAccountsPayable,
            },
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "accounts-payable-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pay-installment-modal", {
        ref: "payInstallmentModal",
        on: {
          "after-confirm": function (ref) {
            var itemList = ref.itemList

            return _vm.onAfterPay(itemList)
          },
          "remove-installment": function (item) {
            _vm.removeInstallmentsToPay(item)
            _vm.getData()
          },
        },
      }),
      _c("accounts-payable-installment-info-sidebar", {
        ref: "accountsPayableInstallmentInfoSidebar",
      }),
      _c("fab", {
        attrs: {
          "main-tooltip": _vm.$t("Ações"),
          "main-icon": "keyboard_command_key",
          "fixed-tooltip": true,
          actions: _vm.fabActions,
        },
        on: {
          "clean-bulk-payment": _vm.cleanBulkPayemnt,
          "confirm-bulk-payment": _vm.showBulkPayModal,
          "add-new": _vm.goToCreateBill,
          "add-import": _vm.createBulkBill,
          click: function () {},
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }