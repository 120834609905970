var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      show: _vm.showSidebar,
      fetching: _vm.loading,
      title: _vm.$t("Detalhes da despesa"),
      width: "850px",
      "no-close-on-backdrop": false,
    },
    on: { hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "b-card-actions",
              { attrs: { title: _vm.$t("Dados"), "no-actions": "" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-id",
                            type: "label",
                            label: _vm.$t("Id da parcela"),
                          },
                          model: {
                            value: _vm.data.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "id", $$v)
                            },
                            expression: "data.id",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-installment",
                            value: _vm.getInstallment,
                            type: "label",
                            label: _vm.$t("Parcela"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-store",
                            type: "label",
                            filter: "storeName",
                            label: _vm.$t("Loja"),
                          },
                          model: {
                            value: _vm.data.accountsPayable.store,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.accountsPayable, "store", $$v)
                            },
                            expression: "data.accountsPayable.store",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-document",
                            value: _vm.getDocument,
                            type: "label",
                            label: _vm.$t("Nº documento"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-description",
                            type: "label",
                            label: _vm.$t("Descrição"),
                          },
                          model: {
                            value: _vm.data.accountsPayable.description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.data.accountsPayable,
                                "description",
                                $$v
                              )
                            },
                            expression: "data.accountsPayable.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-observation",
                            value: _vm.getObservation,
                            type: "label",
                            label: _vm.$t("Observação"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-statement_date",
                            type: "label",
                            filter: "date",
                            label: _vm.$t("Data da emissão"),
                          },
                          model: {
                            value: _vm.data.accountsPayable.statementDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.data.accountsPayable,
                                "statementDate",
                                $$v
                              )
                            },
                            expression: "data.accountsPayable.statementDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-due_date",
                            type: "label",
                            filter: "date",
                            label: _vm.$t("Data do vencimento"),
                          },
                          model: {
                            value: _vm.data.dueDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "dueDate", $$v)
                            },
                            expression: "data.dueDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: _vm.hasPurchaseOrSale ? "4" : "6" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-checking_account",
                            value:
                              _vm.data.accountsPayable.managementAccount.name,
                            type: "label",
                            label: _vm.$t("Conta gerencial"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.hasPurchaseOrSale
                      ? _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _vm.data.accountsPayable.purchaseOrderId
                              ? _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-purchase_order_id",
                                    value:
                                      _vm.data.accountsPayable.purchaseOrderId,
                                    type: "label",
                                    label: _vm.$t("Pedido"),
                                    "router-name": "purchase-order-details",
                                    "router-params": {
                                      id: _vm.data.accountsPayable
                                        .purchaseOrderId,
                                    },
                                    "router-title": _vm.$t(
                                      "Visualizar pedido de compra"
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm.data.accountsPayable.saleId
                              ? _c("FormulateInput", {
                                  attrs: {
                                    id: "acc_pay_sidebar_info-sale_id",
                                    value: _vm.data.accountsPayable.saleId,
                                    type: "label",
                                    label: _vm.$t("Venda"),
                                    "router-name": "order-read-only",
                                    "router-params": {
                                      id: _vm.data.accountsPayable.saleId,
                                    },
                                    "router-title": _vm.$t("Visualizar venda"),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-value",
                            value: _vm.data.value,
                            type: "label",
                            filter: "currency",
                            label: _vm.$t("Valor"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-payment_method",
                            value: _vm.data.paymentMethod.name,
                            type: "label",
                            label: _vm.$t("Forma de pagamento"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _vm.data.accountsPayable.supplier
                          ? _c("FormulateInput", {
                              attrs: {
                                id: "acc_pay_sidebar_info-supplier",
                                value: _vm.data.accountsPayable.supplier,
                                type: "label",
                                filter: "personName",
                                "filter-params": [true],
                                label: _vm.$t("Fornecedor"),
                              },
                            })
                          : _vm._e(),
                        _vm.data.accountsPayable.customer
                          ? _c("FormulateInput", {
                              attrs: {
                                id: "acc_pay_sidebar_info-customer",
                                value: _vm.data.accountsPayable.customer,
                                type: "label",
                                filter: "personName",
                                label: _vm.$t("Cliente"),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-created_by",
                            value: _vm.getCreatedBy,
                            type: "label",
                            label: _vm.$t("Criado por"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.getModifiedBy
                      ? _c(
                          "b-col",
                          { attrs: { md: "4" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "acc_pay_sidebar_info-modified_by",
                                value: _vm.getModifiedBy,
                                type: "label",
                                label: _vm.$t("Alterado por"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-card-actions",
              {
                attrs: { title: _vm.$t("Detalhes da baixa"), "no-actions": "" },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-payment_date",
                            type: "label",
                            filter: "date",
                            label: _vm.$t("Data do pagamento"),
                          },
                          model: {
                            value: _vm.data.paymentDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "paymentDate", $$v)
                            },
                            expression: "data.paymentDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-checking_account",
                            value: _vm.data.checkingAccount.name,
                            type: "label",
                            label: _vm.$t("Conta corrente"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "3" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-payment_value",
                            value: _vm.data.paymentValue,
                            type: "label",
                            filter: "currency",
                            label: _vm.$t("Valor Pago"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-discount_value",
                            value: _vm.data.discount,
                            type: "label",
                            filter: "currency",
                            label: _vm.$t("Desconto"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "2" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "acc_pay_sidebar_info-interest_value",
                            value: _vm.data.interest,
                            type: "label",
                            filter: "currency",
                            label: _vm.$t("Juros"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.getPaidBy
                      ? _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "acc_pay_sidebar_info-paid_by",
                                value: _vm.getPaidBy,
                                type: "label",
                                label: _vm.data.financialWriteOffIsActive
                                  ? _vm.$t("Baixado por")
                                  : _vm.$t("Baixa estornada por"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "sidebar-footer",
        fn: function () {
          return [
            _c("e-button", {
              attrs: {
                variant: "primary",
                icon: "x",
                text: _vm.$t("Fechar"),
                busy: _vm.saving,
              },
              on: { click: _vm.hide },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }