<template>
  <e-sidebar
    :show="showSidebar"
    :fetching="loading"
    :title="$t('Detalhes da despesa')"
    width="850px"
    :no-close-on-backdrop="false"
    @hidden="hide"
  >
    <template #content>
      <b-card-actions
        :title="$t('Dados')"
        no-actions
      >
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="acc_pay_sidebar_info-id"
              v-model="data.id"
              type="label"
              :label="$t('Id da parcela')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="acc_pay_sidebar_info-installment"
              :value="getInstallment"
              type="label"
              :label="$t('Parcela')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="acc_pay_sidebar_info-store"
              v-model="data.accountsPayable.store"
              type="label"
              filter="storeName"
              :label="$t('Loja')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="acc_pay_sidebar_info-document"
              :value="getDocument"
              type="label"
              :label="$t('Nº documento')"
            />
          </b-col>

          <b-col md="6">
            <FormulateInput
              id="acc_pay_sidebar_info-description"
              v-model="data.accountsPayable.description"
              type="label"
              :label="$t('Descrição')"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="acc_pay_sidebar_info-observation"
              :value="getObservation"
              type="label"
              :label="$t('Observação')"
            />
          </b-col>

          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-statement_date"
              v-model="data.accountsPayable.statementDate"
              type="label"
              filter="date"
              :label="$t('Data da emissão')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-due_date"
              v-model="data.dueDate"
              type="label"
              filter="date"
              :label="$t('Data do vencimento')"
            />
          </b-col>
          <b-col :md="hasPurchaseOrSale ? '4' : '6'">
            <FormulateInput
              id="acc_pay_sidebar_info-checking_account"
              :value="data.accountsPayable.managementAccount.name"
              type="label"
              :label="$t('Conta gerencial')"
            />
          </b-col>
          <b-col
            v-if="hasPurchaseOrSale"
            md="2"
          >
            <FormulateInput
              v-if="data.accountsPayable.purchaseOrderId"
              id="acc_pay_sidebar_info-purchase_order_id"
              :value="data.accountsPayable.purchaseOrderId"
              type="label"
              :label="$t('Pedido')"
              router-name="purchase-order-details"
              :router-params="{ id: data.accountsPayable.purchaseOrderId }"
              :router-title="$t('Visualizar pedido de compra')"
            />
            <FormulateInput
              v-if="data.accountsPayable.saleId"
              id="acc_pay_sidebar_info-sale_id"
              :value="data.accountsPayable.saleId"
              type="label"
              :label="$t('Venda')"
              router-name="order-read-only"
              :router-params="{ id: data.accountsPayable.saleId }"
              :router-title="$t('Visualizar venda')"
            />
          </b-col>

          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-value"
              :value="data.value"
              type="label"
              filter="currency"
              :label="$t('Valor')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-payment_method"
              :value="data.paymentMethod.name"
              type="label"
              :label="$t('Forma de pagamento')"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              v-if="data.accountsPayable.supplier"
              id="acc_pay_sidebar_info-supplier"
              :value="data.accountsPayable.supplier"
              type="label"
              filter="personName"
              :filter-params="[true]"
              :label="$t('Fornecedor')"
            />
            <FormulateInput
              v-if="data.accountsPayable.customer"
              id="acc_pay_sidebar_info-customer"
              :value="data.accountsPayable.customer"
              type="label"
              filter="personName"
              :label="$t('Cliente')"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="acc_pay_sidebar_info-created_by"
              :value="getCreatedBy"
              type="label"
              :label="$t('Criado por')"
            />
          </b-col>
          <b-col
            v-if="getModifiedBy"
            md="4"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-modified_by"
              :value="getModifiedBy"
              type="label"
              :label="$t('Alterado por')"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        :title="$t('Detalhes da baixa')"
        no-actions
      >
        <b-row>
          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-payment_date"
              v-model="data.paymentDate"
              type="label"
              filter="date"
              :label="$t('Data do pagamento')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-checking_account"
              :value="data.checkingAccount.name"
              type="label"
              :label="$t('Conta corrente')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="acc_pay_sidebar_info-payment_value"
              :value="data.paymentValue"
              type="label"
              filter="currency"
              :label="$t('Valor Pago')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="acc_pay_sidebar_info-discount_value"
              :value="data.discount"
              type="label"
              filter="currency"
              :label="$t('Desconto')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="acc_pay_sidebar_info-interest_value"
              :value="data.interest"
              type="label"
              filter="currency"
              :label="$t('Juros')"
            />
          </b-col>

          <b-col
            v-if="getPaidBy"
            md="6"
          >
            <FormulateInput
              id="acc_pay_sidebar_info-paid_by"
              :value="getPaidBy"
              type="label"
              :label="
                data.financialWriteOffIsActive ? $t('Baixado por') : $t('Baixa estornada por')
              "
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </template>

    <template #sidebar-footer>
      <e-button
        variant="primary"
        icon="x"
        :text="$t('Fechar')"
        :busy="saving"
        @click="hide"
      />
      <!-- <e-button
        v-if="showReverse"
        class="ml-1"
        :busy="saving"
        variant="danger"
        icon="x-circle"
        :text="$t('Estornar')"
        @click="localReverse"
      /> -->
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { alerts, financialDomain } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ESidebar from '@/views/components/ESidebar.vue'
import EButton from '@/views/components/EButton.vue'

const getInitialSidebarData = () => ({
  id: null,
  store: null,
  user: null,
  description: null,
  value: null,
  paymentDate: null,
  paymentMethod: { name: null },
  accountsPayable: {
    supplier: null,
    customer: null,
    managementAccount: { name: null },
  },
  checkingAccount: { name: null },
})

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    ESidebar,
    EButton,
  },

  mixins: [alerts, financialDomain],

  props: {},

  data() {
    return {
      showSidebar: false,
      loading: false,
      saving: false,
      data: getInitialSidebarData(),
    }
  },

  computed: {
    getCreatedBy() {
      if (!this.data.createdDate) return null
      return `${this.data.createdBy} (${this.$options.filters.date(this.data.createdDate)})`
    },
    getModifiedBy() {
      if (!this.data.modifiedDate) return null
      return `${this.data.modifiedBy} (${this.$options.filters.date(this.data.modifiedDate)})`
    },
    getPaidBy() {
      if (!this.data.writtenOffDate) return null
      return `${this.data.writtenOffBy} (${this.$options.filters.date(this.data.writtenOffDate)})`
    },
    getObservation() {
      return [this.data.accountsPayable.comments, this.data.comments].filter(Boolean).join(' - ')
    },
    getDocument() {
      if (!this.data.accountsPayable.document) return null
      return `${this.data.accountsPayable.document}${
        this.data.accountsPayable.documentType ? ` (${this.data.accountsPayable.documentType})` : ''
      }`
    },
    getInstallment() {
      return [this.data.installment, this.data.accountsPayable.installmentCount]
        .filter(Boolean)
        .join('/')
    },

    hasPurchaseOrSale() {
      return this.data.accountsPayable.purchaseOrderId || this.data.accountsPayable.saleId
    },

    showReverse() {
      return this.$can('Reverse', 'FinancialWriteOff')
    },
  },

  methods: {
    async show(existingData) {
      this.showSidebar = true
      this.loading = true

      try {
        if (existingData) {
          const defaultData = getInitialSidebarData()
          const { accountsPayable } = defaultData
          this.data = {
            ...existingData,
            accountsPayable: {
              ...existingData.accountsPayable,
              managementAccount:
                existingData.accountsPayable.managementAccount || accountsPayable.managementAccount,
              supplier: existingData.accountsPayable.supplier || accountsPayable.supplier,
              customer: existingData.accountsPayable.customer || accountsPayable.customer,
            },
            checkingAccount: existingData.checkingAccount || defaultData.checkingAccount,
          }
        } else {
          // TODO ter uma busca por id da parcela?
          // const { data } = await this.$http.get(
          //   `/api/accounting/accounts-payable-installment/${installmentId}`
          // )
          // this.data = data
        }
      } catch (error) {
        this.showError({ error })
        this.hide()
      } finally {
        this.loading = false
      }
    },

    async localReverse() {
      await this.reverseWriteOff()
    },

    async reverseWriteOff() {
      const { accountsPayableInstalment } = this.data
      let writeOffId = null
      let writeOffMessage = ''

      writeOffId = accountsPayableInstalment[0]?.financialWriteOffId
      writeOffMessage = this.$t(
        'O pagamento será cancelado e o lançamento na conta corrente será excluído.'
      )

      const confirmed = await this.confirm({
        text: writeOffMessage,
      })
      if (!confirmed) return

      try {
        this.saving = true
        await this.$http.patch(`/api/financial/write-offs/${writeOffId}/reverse-payment`)
        this.showSuccess({ message: this.$t('Estornado com sucesso') })
        this.$emit('after-reverse')
        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    cleanData() {
      this.data = getInitialSidebarData()
    },
    hide() {
      this.cleanData()
      this.showSidebar = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
